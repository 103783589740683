import React from "react";
import "../scss/main.scss";
import Layout from "../components/Layout";
import Item from "../components/Item";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <div className="banner-section">
        <div className="banner">
          <div className="title-box">
            <span className="title-box__text first-row-text">
              Книжки для вас
            </span>
            <span className="title-box__text senond-row-text">
              за найкращими цінами
            </span>
            <div className="link-box">
              <Link to="/bookstore">
                <span className="title-box__text">До Книгарні</span>
              </Link>
              <Link className="link-box__link" to="/bookstore">
                <StaticImage src="../images/homepage_vector_icon.png" alt="homepage_vector_icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="content-section">
        <div className="container">
            <span className="content-section__title">Наша філософія</span>
            {data.settings.childConfigYaml.philosophy.map(text => {
              return <p className="content-section__text" key={text.slice(0,5)}>{text}</p>
            })}
        </div>
      </div>
      <div className="content-section">
        <div className="container">
          <span className="content-section__title">Новинки продажу</span>
          <div className="books-card-row">
            {data.books.edges.slice(0, data.settings.childConfigYaml.newBooksOnMainPage).map(book => {
              return <Item key={book.node.id} bookitem={book.node} />;
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const MainPageQuery = graphql`
  query MainPageQuery {
    books: allMarkdownRemark(
      filter: {frontmatter: {inStock: {eq: true}}}
      sort: {fields: frontmatter___datePosted, order: DESC}
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            sku
            title
            author
            price
            category
            inStock
            datePosted
            gallery {
              image {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    settings: file(name: {eq: "site"}) {
      childConfigYaml {
        newBooksOnMainPage
        philosophy
      }
    }
  }
`;

export default IndexPage;
